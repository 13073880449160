// swiperjs

// Initializing Swiper
const swiper = new Swiper('.section__featured--block', {
    slidesPerView: 1,
    spaceBetween: 15,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        100: {
            slidesPerView: 1,
        },
        1024: {
            slidesPerView: 2,
        },
    },
})

const swiper2 = new Swiper('.section__reviews--block', {
    slidesPerView: 4,
    spaceBetween: 15,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        100: {
            slidesPerView: 1,
        },
        1024: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        },
    },
})